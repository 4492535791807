import Pikaday from 'pikaday'
import { createUser } from './api'
import buildConfig from './config'
import Form from './Form'
import buildTranslator from './locale'

import '../node_modules/pikaday/css/pikaday.css'
import ErrorMessages from './ErrorMessages'

export default class SignupWidget {
  constructor(config) {
    this.config = buildConfig(config)
    this.translator = buildTranslator(this.config.locale)

    this.urlParams = this.readParams()

    this.mountContainer()
    this.mountForm()
    this.addGoogleRecaptchaScript({})
  }

  addGoogleRecaptchaScript = ({ onLoad }) => {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/enterprise.js'
    script.type = 'text/javascript'
    script.async = false
    script.onload = onLoad
    document.head.appendChild(script)
  }

  renderGoogleRecaptcha = () => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.render(
        this.config.styles.recaptchaContainerId,
        {
          sitekey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
        }
      )
    })

    this.form.mountGoogleRecaptcha()
  }

  readParams = () => {
    const params = {}
    const urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.forEach((value, key) => {
      params[key] = value
    })

    return params
  }

  mountContainer = () => {
    this.containerElement = document.querySelector(
      this.config.mapping.container
    )
    if (!this.containerElement) {
      throw this.translator.t('no_container_found', {
        selector: this.config.mapping.container,
      })
    }
  }

  mountForm = () => {
    const elements = this.buildElements()

    this.form = new Form(elements, this.config)
    if (this.form) {
      this.form.onValidateFailed(this.config.callbacks.onValidateFailed)
      this.form.onRequestSucceeded(this.handleRequestSucceeded)
      this.form.onRequestFailed(this.handleRequestFailed)
      this.form.onBeforeSubmit(this.handleBeforeSubmit)
      this.form.onSubmit(this.handleSubmit)
    }

    if (!this.config.useCustomErrorElement) {
      this.errorMessages = new ErrorMessages({
        config: this.config,
        containerHeight: this.containerElement.clientHeight,
      })

      this.containerElement.prepend(this.errorMessages.htmlElement())
    }
  }

  buildElements = () => {
    const elements = {}
    const form = this.containerElement.querySelector('form')
    elements.form = form
    Object.keys(this.config.mapping.fields).forEach((key) => {
      elements[key] = form.querySelector(this.config.mapping.fields[key])
    })

    elements.submit_button = elements.form.querySelector(
      'button[type="submit"]'
    )
    elements.captcha_token = elements.form.querySelector('input#captcha_token')

    if (elements.birthday) {
      new Pikaday({
        field: document.getElementById('birthday'),
        yearRange: [1930, new Date().getFullYear()],
      })
    }

    return elements
  }

  handleSubmit = (values) => {
    if (this.config.test.forceRequestError) {
      return this.handleRequestFailed([this.translator.t('request.error_test')])
    }

    const requestParams = {
      ...this.urlParams,
      ...this.config.params,
      ...values,
      'g-recaptcha-response': values.captcha_token,
      program_code: this.config.programCode,
      sign_up_configuration_id: this.config.configurationId,
      agree_to_tos: true,
    }

    createUser(requestParams, {
      'Content-Type': 'application/json',
    })
      .then((response) => {
        if (response.status === 429) {
          this.form.changeSubmitButtonDisabled(false)
          return this.renderGoogleRecaptcha()
        }
        return response
          .json()
          .then((body) =>
            response.ok
              ? this.handleRequestSucceeded(body)
              : this.handleRequestFailed(this.requestError(body))
          )
      })
      .catch(() => this.handleRequestDefaultError())

    return true
  }

  requestError = (body) => {
    if (body?.flash?.error) {
      return [body.flash.error]
    }
    return body?.errors?.full_messages || this.defaultErrorMessage()
  }

  handleRequestDefaultError = () => {
    this.handleRequestFailed([this.translator.t('request.default_error')])
  }

  formatErrorMessages = (errors) =>
    errors.full_messages
      ? errors.full_messages
      : [this.translator.t('request.default_error')]

  handleBeforeSubmit = () => {
    this.setErrorMessages([])
  }

  handleRequestSucceeded = (value) => {
    this.form.changeSubmitButtonDisabled(false)
    if (this.config.callbacks.onRequestSucceeded) {
      this.config.callbacks.onRequestSucceeded()
    }
    window.location.replace(this.config.redirectUrl || value.after_sign_in_path)
  }

  handleRequestFailed = (errors) => {
    this.setErrorMessages(errors)
    if (this.config.errorMessages?.trialMessage && this.config.freeTrialLink) {
      this.errorMessages.setTrialMessage(
        this.config.errorMessages.trialMessage,
        this.config.freeTrialLink,
        this.urlParams
      )
    }
    this.form.changeSubmitButtonDisabled(false)
    this.config.callbacks.onRequestFailed(errors)
  }

  setErrorMessages = (errors) => {
    if (!this.config.useCustomErrorElement && this.errorMessages) {
      return this.errorMessages.setErrors(errors)
    }
    return false
  }
}
