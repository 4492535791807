import queryString from 'query-string'
import errorMessagesTemplate from '../templates/errorMessages.html'

class ErrorMessages {
  constructor({ containerHeight, config }) {
    this.containerHeight = containerHeight
    this.config = config

    this.errorMessagesElement = this.buildElements()
  }

  buildElements = () => {
    const container = document.createElement('div')
    container.innerHTML = errorMessagesTemplate

    const element = container.firstChild
    element.classList.add(this.config.styles.errorMessagesContainerClass)

    element.style.height = `${this.containerHeight}px`

    const [generalErrorMessages, freeTrialMessage] = element.children
    generalErrorMessages.classList.add(
      this.config.styles.generalErrorMessagesClass
    )
    freeTrialMessage.classList.add(this.config.styles.freeTrialMessageClass)

    const tryAgainButton = generalErrorMessages.querySelector('.try-again-btn')
    tryAgainButton.addEventListener('click', () => {
      element.style.display = 'none'
    })

    return element
  }

  setTrialMessage = (message, freeTrialLink, urlParams) => {
    const freeTrialMessage = this.errorMessagesElement.querySelector(
      `.${this.config.styles.freeTrialMessageClass}`
    )
    if (!freeTrialMessage) {
      return false
    }
    const messageElement = freeTrialMessage.querySelector('.message')
    messageElement.innerHTML = message

    const freeTrialButton = freeTrialMessage.querySelector('.free-trial-btn')
    freeTrialButton.textContent = this.config.freeTrialButtonLabel
    freeTrialButton.addEventListener('click', (e) => {
      e.preventDefault()
      const query = queryString.stringify(urlParams)

      window.open(`${freeTrialLink}?${query}`, '_blank')

      return false
    })

    freeTrialMessage.style.display = ''
    return true
  }

  setErrors = (errors) => {
    if (this.errorMessagesElement) {
      const errorsList = this.errorMessagesElement.querySelector('ul')
      errorsList.childNodes.forEach((node) => {
        errorsList.removeChild(node)
      })
      ;(errors || []).forEach((error) => {
        const li = document.createElement('li')
        const span = document.createElement('span')
        span.innerHTML = error
        li.appendChild(span)
        errorsList.appendChild(li)
      })

      if (!errors || errors.length > 0) {
        this.errorMessagesElement.style.display = ''
      }
    }
  }

  htmlElement = () => this.errorMessagesElement
}

export default ErrorMessages
