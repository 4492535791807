import Polyglot from 'node-polyglot'

const enMessages = require('../locales/messages.json')
const esMessages = require('../locales/es.messages.json')

const en = new Polyglot({
  locale: 'en',
  phrases: enMessages,
})
const es = new Polyglot({
  locale: 'es',
  phrases: esMessages,
})

const availableLocales = {
  en,
  es,
}

export default (locale) => availableLocales[locale.toLowerCase()]
