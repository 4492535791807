import SignupWidget from './SignupWidget'

const init = (programCode, configurationId, params, config) => {
  new SignupWidget({
    programCode,
    configurationId,
    params,
    ...config,
  })
}
/* eslint-disable import/prefer-default-export */
export { init }
